<script>
import NavHeader from "@/components/top/NavHeader.vue";
import FenXiangTiShi from "@/components/btn/fenXiangTiShi.vue";
import {useRoute} from "vue-router";
import {isEmpty} from "lodash";
import {find, get, isUndefined, toInteger} from "xe-utils";
import qiYeWeiXi from "@/api/QiYeWeiXi.js";
import {computed, onMounted, ref} from "vue";
import http from "@/api/http.js";
import isArray from "xe-utils/isArray";
import FooterNav from "@/views/open/footerNav.vue";

export default {
  components: {FooterNav, FenXiangTiShi, NavHeader},
  setup() {
    const route = useRoute()
    const navId = toInteger((isEmpty(route.params.navId) ? 1087 : route.params.navId))
    const navId2 = ref(get(route, 'params.navId2', ''));
    const navList = [
      {
        name: '新建项目',
        pageName: 'approvalProcess',
        navId: 1087
      },
      {
        name: '改扩建项目',
        pageName: 'approvalProcess',
        navId: 1088
      }
    ]
    onMounted(() => {
      http.get('/gzhphp/policy/get_fen_lei', {params: {navId}}).then(da => {
        if (da.code === 1) {
          fenLeiList.value = da.data
          if (isEmpty(navId2.value)) {
            navId2.value = get(fenLeiList.value, '0.id', '')
          } else {
            navId2.value = toInteger(navId2.value)
          }
        }
      })

      qiYeWeiXi.fenXiang({
        title: get(route, 'meta.title', ''),
        desc: '点击进来查看更多内容',
        link: location.href
      })
    })
    const getAnJiuInfo = computed(() => {
      let info = {
        children: []
      }
      let select = find(fenLeiList.value, n => {
        return n.id === navId2.value
      })
      if (!isUndefined(select)) {
        info = select
      }

      if (!isArray(info.children)) info.children = []

      return info
    })

    const fenLeiList = ref([])


    return {
      get,
      getAnJiuInfo,
      navId2,
      fenLeiList,
      route,
      navList,
      navId
    }
  }
}
</script>

<template>
  <div class="">
    <nav-header fixed :title="get(route, 'meta.title', '')">
      <template #right>
        <fen-xiang-ti-shi/>
      </template>
    </nav-header>

    <div class="tal-flex dao-hang tal-bg-white tal-text-center">
      <router-link
          class="tal-text-black "
          :to="{name:vo.pageName,params:{navId:vo.navId}}"
          v-for="(vo,i) in navList"
          :key="i"
          :class="{'action':vo.navId === navId}"
      >
        {{ vo.name }}
      </router-link>
    </div>

    <div class="tal-grid tal-grid-cols-3 tal-gap-3 tal-gap-y-5 tal-text-center tal-mt-5 er-ji-dao-hang">
      <div v-for="(vo,i) in fenLeiList" :key="i">
        <div>
          <router-link
              :to="{name:'approvalProcess',params:{navId:navId,navId2:vo.id}}"
              class="tal-bg-gray-200 an-niu"
              :class="{'action':navId2 === vo.id}"
          >
            {{ vo.name }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="tal-gap-10 tal-mt-7">
      <h5 class="tal-text-3xl tal-text-center">{{ getAnJiuInfo.content_miao_shi }}</h5>
      <div class="tal-grid tal-grid-cols-2  tal-text-center tal- tal-text-2xl">
        <div
            v-for="(vo,i) in getAnJiuInfo.children"
            :key="i"
            class="tal-pl-2 tal-pr-2 tal-mt-6"
        >

          <router-link
              v-if="vo.json_shen_pi_guo_cheng_info_count"
              :to="{name:'approvalProcessInfo',params:{navMenuId:vo.id}}"
          >
            {{ vo.name }}
          </router-link>
          <span class="tal-text-gray-500" v-else>
             {{ vo.name }}
          </span>
        </div>
      </div>
    </div>
    <footer-nav fixed />
  </div>
</template>

<style scoped lang="less">
@import "~@/assets/basis_css/style/var";

.dao-hang {
  flex-direction: row;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-evenly;

  > a {
    height: 44px;
    line-height: 44px;
    border-bottom: 2px solid transparent;

    &.action {
      border-color: @blue;
      color: @blue;
    }
  }
}

.er-ji-dao-hang {
  font-size: 12px;

  > div {
    padding-top: 5px;

    .an-niu {
      padding: 18px;
      padding-top: 6px;
      padding-bottom: 6px;
      border-radius: 5px;
      color: @gray-8;
      font-size: 16px;

      &.action {
        background: @blue;
        color: @white;
      }
    }
  }
}
</style>
